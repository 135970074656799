declare var System: any;

import { Component } from '@angular/core';
// import { Subscription } from 'rxjs/Rx';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, NavigationEnd, GuardsCheckEnd } from '@angular/router';
import { AppAuthGuard } from '../../../auth/services/auth-guard.service';
import { LazyInjector } from '../../services/lazy-injector';
import { AnalyticsService } from '../../services/analytics.service';
import { AppStatusService } from '../../services/app-status.service';
import { ConfigService } from '../../services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../../services/localization.service';
import * as _ from 'lodash';

@Component({
  selector: 'gloodoo',
  styleUrls: ['gloodoo.component.scss'],
  template: `
    <router-outlet></router-outlet>
    <modal-outlet></modal-outlet>
    <div *ngIf="loadingTranslations || loadingApp" class="splash-screen">
      <div class="splash-screen__content">
        <img src="assets/brand/iwos-script.svg" class="splash-screen__logo"/>
        <div class="splash-screen__loader">
          <svg xmlns="http://www.w3.org/2000/svg" class="splash-adapt-loading-container" viewBox="0 0 50 50">
              <circle class="splash-adapt-loading-path" cx="25" cy="25" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
    </div>
  `
})
export class GloodooComponent {
  private tenantConfig: any = _.get(window, 'GDConfig.config') as any;
  private appConfig: any = _.get(window, 'GDConfig.app') as any;
  loadingTranslations: boolean = true;
  loadingApp: boolean = true;

  constructor (
    private appStatus: AppStatusService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private lazyInjector: LazyInjector,
    private localization: LocalizationService,
    private router: Router,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.loadAppPackage(`${ this.appConfig.path }/app.js`);
    this.loadInitialConfig();

    if (!!this.tenantConfig) {
      let config = this.tenantConfig;
      if (config['AAD.TenantId']) {
        this.configService.setValue('core', 'aadTenantId', config['AAD.TenantId']);
      }

      if (config['AAD.ApplicationId']) {
        this.configService.setValue('core', 'aadApplicationId', config['AAD.ApplicationId']);
      }

      if (config['IdentityProvider']) {
        this.configService.setValue('core', 'identityProvider', config['IdentityProvider']);
      }

      if(config['InactivityLogoutSecs']) {
        this.configService.setValue('core', 'inactivityLogoutSecs', config['InactivityLogoutSecs']);
      }
    }

    // if (this.appConfig.jsDependencies) {
    //   this.lazyInjector.injectJSDependencies(this.appConfig.jsDependencies);
    // }

    if (analyticsService.isAvailable()) {
      this.analyticsService.submitEvent('User', 'Browser', navigator.userAgent);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          let lat: number = position.coords.latitude;
          let long: number = position.coords.longitude;
          this.analyticsService.submitEvent('User', 'Location', 'latitude: ' + lat + ', longitude: ' + long);
        });
      }
    }

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
         var title = this.getTitleFromRoot(router.routerState.root) || 'Accenture IWOS';
        // Get title from routing or use 'Accenture IWOS'
        titleService.setTitle(title);
        if (analyticsService.isAvailable()) {
          analyticsService.setPage(event.urlAfterRedirects);
        }
      } else if (event instanceof GuardsCheckEnd && event.shouldActivate) {
        this.loadingApp = false;
      }
    });

    // Load english language
    const browserLang: string = 'en';
    localization.injectTranslations(`/assets/i18n/${browserLang}.json`, browserLang, true).then(result => {
      localization.coreTranslationsLoaded.next(true);
      this.loadingTranslations = false;
    }).catch(error => {
      console.warn('No language to load, defaulting to english');
      localization.injectTranslations('/assets/i18n/en.json', 'en', true).then(result => {
        localization.coreTranslationsLoaded.next(true);
        localization.setLanguage('en');
        this.loadingTranslations = false;
      })
      .catch(error => {
        console.warn('error loading default lang: ', error);
      });
    });
    localization.setLanguage(browserLang);
  }

  private getTitleFromRoot (state: ActivatedRoute): string {
    // Recursively read routeConfig down to the current route to find a title.
    let title = '';
    if (state && state.firstChild) {
      title = this.getTitleFromRoot(state.firstChild);
    }

    return title ? title : state.snapshot.data['title'];
  }

  private loadAppPackage (appSrc: string): void {

    // System.import(appSrc)
    //   .then((appConfig) => {
        // appConfig = appConfig.default;
        const appConfig={ id: 'adapt.portal', 
        name: 'Accenture IWOS Portal',
        type: 'module',
        moduleClass: 'PortalModule'}

        // unshift route to give precedence
        // let routerConfig = this.router['config'];
        // routerConfig.unshift({
        //   path: 'app',
        //   loadChildren: () => import(`${ this.appConfig.path }/app.module`).then(m=>m[appConfig.moduleClass]),
        //   canActivate: [ AppAuthGuard ]
        // });

        // this.router.resetConfig(routerConfig);
        this.appStatus.appRouteReady();
      // })
      // .catch(error => {
      //   console.warn('error loading app: ', error);
      // });
  }

  private loadInitialConfig (): void {
    let coreConfig: any = {};
    let standaloneAppConfig: any = {};
    let brandingConfig: any = {};

    if (this.appConfig) {
      let config = this.parseJson(this.appConfig.config);

      for (let key in config) {
        if (key.startsWith('__core.'))
          coreConfig[key.substring(7, key.length)] = config[key];
        else
          standaloneAppConfig[key] = config[key];
      }
      this.configService.addConfig('core', coreConfig, true);
      this.configService.addConfig(this.appConfig.id, standaloneAppConfig, true);

      if (this.appConfig.branding) {
        brandingConfig = this.parseJson(this.appConfig.branding);
      }
      this.configService.setValue('core', 'branding', brandingConfig);
    }
  }

  private parseJson(str: string): any {
    try {
      return JSON.parse(str);
    }
    catch (e) {
      return {};
    }
  }
}
