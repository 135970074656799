import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from './models/Directory_models';

export * from './models/Directory_models';

@Injectable({
  providedIn: 'root'
})
export class DirectoryApiClientService {

  domain: string;

  constructor (public http: HttpClient) {}

  public setApiDomain (domain): void {
    this.domain = domain;
  }

  public setHttpProvider (httpProvider): void {
    this.http = httpProvider;
  }

  /**
  * 
  * @method GET
  * @name AuthServiceLogOut
  *
  */
  public AuthServiceLogOut (): Observable<any> {
    const uri = `/auth/logout`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };


    return this.http
      .request('GET', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name AuthServicePostChangePassword
  * @param {models.IAuthPassword} request - 
  *
  */
  public AuthServicePostChangePassword (request: models.IAuthPassword | string): Observable<any> {
    const uri = `/auth/password`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name AuthServicePostForgottenPassword
  * @param {models.IForgottenPasswordRequest} request - 
  *
  */
  public AuthServicePostForgottenPassword (request: models.IForgottenPasswordRequest | string): Observable<any> {
    const uri = `/auth/password/forgotten`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }

  /**
  * 
  * @method GET
  * @name AuthServiceGetPasswordPolicy
  * @param {string} tenantId - 
  *
  */
  public AuthServiceGetPasswordPolicy (tenantId?: string | string): Observable<any> {
    const uri = `/auth/password/policy`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };

    if (tenantId !== undefined){
      options.params = options.params.set('tenantId', tenantId);
    }

    return this.http
      .request('GET', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name AuthServicePostResetPassword
  * @param {models.IAuthPasswordReset} request - 
  *
  */
  public AuthServicePostResetPassword (request: models.IAuthPasswordReset | string): Observable<any> {
    const uri = `/auth/password/reset`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }

  /**
  * 
  * @method GET
  * @name AuthServiceGetPasswordValidateToken
  * @param {string} token - 
  *
  */
  public AuthServiceGetPasswordValidateToken (token?: string | string): Observable<any> {
    const uri = `/auth/password/validatetoken`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };

    if (token !== undefined){
      options.params = options.params.set('token', token);
    }

    return this.http
      .request('GET', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name AuthServicePostToken
  * @param {models.IOauthTokenRequest} request - 
  *
  */
  public AuthServicePostToken (request: models.IOauthTokenRequest | string): Observable<any> {
    const uri = `/auth/token`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }

  /**
  * 
  * @method GET
  * @name AuthServiceGetTermsOfService
  * @param {string} token - 
  *
  */
  public AuthServiceGetTermsOfService (token?: string | string): Observable<any> {
    const uri = `/auth/tos`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };

    if (token !== undefined){
      options.params = options.params.set('token', token);
    }

    return this.http
      .request('GET', this.domain + uri, options);
  }

  /**
  * 
  * @method GET
  * @name AuthServiceAcceptTermsOfService
  * @param {string} token - 
  *
  */
  public AuthServiceAcceptTermsOfService (token?: string | string): Observable<any> {
    const uri = `/auth/tos/accept`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'text' as 'json'
    };

    if (token !== undefined){
      options.params = options.params.set('token', token);
    }

    return this.http
      .request('GET', this.domain + uri, options);
  }


}
