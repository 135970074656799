import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalizationService } from './localization.service';

@Injectable()
export class LocalizationGuard implements CanActivate {
  constructor(private localizationService: LocalizationService) {}

  public canActivate (): Promise<boolean> {
    return new Promise(resolve => {
      this.localizationService.coreTranslationsLoaded.subscribe(loaded => {
        if (loaded) {
          resolve(true);
        }
      });
    });
  }
}
