import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocalizationService {
  constructor (private translateService: TranslateService, private httpClient: HttpClient) {}

  public coreTranslationsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public getTranslateService (): TranslateService {
    return this.translateService;
  }

  public setLanguage (lang: string): void {
    this.translateService.use(lang);
  }

  public injectTranslations (url: string, lang: string, setAsDefault?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      const options: HttpRequest<any> = new HttpRequest('GET', url);
      this.httpClient.request(options).subscribe((data: any) => {
          try {
            this.translateService.setTranslation(lang, data?.body, true);
            if (setAsDefault) {
              this.translateService.setDefaultLang(lang);
            }
            resolve();
          } catch {
            console.warn('loaded localization data not valid');
            reject();
          }
        },
        error => {
          console.warn('Error loading translations: ', error);
          reject();
        }
      );
    });
  }
}
