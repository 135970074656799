import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { FileUploadModule } from 'ng2-file-upload';
import { gloodooRoutes, gloodooViews } from './core.routing';



export * from './core/auth/index';
export * from './core/core/index';
export * from './core/notices/index';

export * from './api/profile/index';

export * from './material.module';

export { FileUploadModule } from 'ng2-file-upload';

import {
  DirectoryApiClientService } from './api/directory/index';
import {
  ProfileApiClientService } from './api/profile/index';
import {
  MyTenantApiClientService } from './api/mytenant/index';
import {
  AnalyticsService,
  AppStatusService,
  ConfigService,
  Deferred404Guard,
  GDSourcePipe,
  GloodooComponent,
  LazyInjector,
  LocalizationService,
  LocalizationGuard,
  NotFoundComponent,
  StorageService,
  TenantConfigService,
  InactivityLogoutService,
  KeyboardService } from './core/core/index';
import {
  AppAuthGuard,
  AuthService,
  ActiveDirectoryService,
  HttpWrapperService,
  LoginAuthGuard,
  InactivityModalComponent,
  TermsOfServiceModalComponent,
  TwoToneHolderComponent,
  SessionService } from './core/auth/index';
import {
  DialogComponent,
  LightsOutComponent,
  DialogService,
  ModalOutletComponent,
  ModalService,
  ToastService } from './core/notices/index';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    MaterialModule,
    FileUploadModule
  ],
  declarations: [
    DialogComponent,
    GDSourcePipe,
    LightsOutComponent,
    ModalOutletComponent,
    NotFoundComponent,
  ],
  exports: [
    DialogComponent,
    GDSourcePipe,
    LightsOutComponent,
    ModalOutletComponent,
    NotFoundComponent,
    MaterialModule,
    TranslateModule
  ],
  providers: [
    AnalyticsService,
    AuthService,
    ActiveDirectoryService,
    ConfigService,
    DialogService,
    DirectoryApiClientService,
    HttpWrapperService,
    LazyInjector,
    LocalizationService,
    ModalService,
    ProfileApiClientService,
    StorageService,
    SessionService,
    MyTenantApiClientService,
    TenantConfigService,
    ToastService,
    Title,
    FormBuilder,
    InactivityLogoutService,
    KeyboardService,
    // Http provider for auth service authenticated methods (circular dependency issue)
    { provide: "HttpWrapperService", useClass: HttpWrapperService }
  ]
})
export class GDWebCoreModule { }

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(gloodooRoutes, { useHash: false }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    GDWebCoreModule,
    MaterialModule
  ],
  providers: [
    AppAuthGuard,
    AppStatusService,
    Deferred404Guard,
    LocalizationGuard,
    LoginAuthGuard
  ],
  declarations: [
    GloodooComponent,
    gloodooViews,
    InactivityModalComponent,
    TermsOfServiceModalComponent,
    TwoToneHolderComponent
  ],
  exports:[
    GloodooComponent
  ]
  // entryComponents: [
  //   InactivityModalComponent,
  //   TermsOfServiceModalComponent
  // ],
 // bootstrap: [ GloodooComponent ]
})
export class GDBootstrapModule { }
